import React from 'react';
import { Grid, Stack, Button, Typography, IconButton, AppBar, Toolbar } from '@mui/material';
import { useState } from 'react';
import { CustomModal } from 'Base/Modal/Modal';
import bannerImg from "../../assets/wordmark 2.svg";
import logoImg from "../../assets/logomark.svg";
import car1 from "../../assets/img/b3.png";
import car2 from "../../assets/img/b4.png";
import car3 from "../../assets/img/B5.png";
import car4 from "../../assets/img/B6.png";
import MenuIcon from '@mui/icons-material/Menu';
import './Banner.css';
import bannerImage from '../../assets/img/bannerImage.png';

const Banner = () => {
  const [open, setOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setMobileMenuOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleMobileMenuItemClick = () => {
    setMobileMenuOpen(false);
    handleOpen();
  };

  const isXs = window.innerWidth < 600;

  return (
    <Grid container>
      <Grid item xs={12} className="mb-[64px]">
        <header
          className="px-4 m-0 tablet:px-[40px] desktop:px-[120px] h-[800px] 
          tablet:h-[800px] w-screen"
          style={{ height: 1020, paddingLeft: isXs ? '3rem' : '5rem', paddingRight: isXs ? '3rem' : '5rem',  }}
        >
          <nav className="w-full mt-4 mb-4">
            <Grid container justifyContent="space-between" alignItems="center">
              <AppBar position="static" sx={{ background: 'transparent', boxShadow: 'none' }}>
                <Toolbar style={{paddingLeft:'0px'}}>
                  <div className="flex w-auto desktop:w-[300px] gap-4 tablet:ml-0 tablet:w-[300px]">
                    <img
                      src={logoImg}
                      alt="first company"
                      className="w-[25px] tablet:w-[50px]"
                      style={{ width: 40, height: 100 }}
                    />
                    <img
                      src={bannerImg}
                      alt="company"
                      className=""
                      style={{ width: 100, height: 100 }}
                    />
                  </div>
                  <div
                    className="hidden tablet:flex tablet:gap-8 tablet:items-center"
                    style={{ marginLeft: 'auto' }}
                  >
                    <a className="banner-play" href="#hero">
                      Why Fortuna?
                    </a>

                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        color: '#1347F5',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        padding: '8px 12px',
                        fontWeight: 600,
                        lineHeight: '28px',
                        letterSpacing: '-0.176px',
                        textTransform: 'none',
                      }}
                      disableElevation
                      disableRipple
                      onClick={() => handleOpen()}
                    >
                      Get Started
                    </Button>
                  </div>
                  <div className="tablet:hidden" style={{ marginLeft: 'auto' }}>
                    <IconButton
                      edge="end"
                      color="inherit"
                      aria-label="menu"
                      onClick={() => handleMobileMenuToggle()}
                      sx={{ color: 'white' }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                </Toolbar>
              </AppBar>

              {/* Mobile Menu */}
              {mobileMenuOpen && (
                <div className="tablet:hidden" style={{ textAlign: 'center', paddingLeft: '35%' }}>
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: '14px',
                      fontFamily: 'Inter',
                      color: '#AAC4FF',
                      textAlign: 'right',
                      width: 'fit-content',
                      cursor: 'pointer',
                      paddingRight: '20px',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => handleMobileMenuToggle()} 
                  >
                    Why Fortuna?
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: '14px',
                      fontFamily: 'Inter',
                      color: '#AAC4FF',
                      textAlign: 'right',
                      width: 'fit-content',
                      cursor: 'pointer',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => handleMobileMenuItemClick()}
                  >
                    Get Started
                  </Typography>
                </div>
              )}
            </Grid>
          </nav>

          <Grid container spacing={2} alignItems="left" style={{ marginTop: isXs ? '0px' : '50px' }}>
     
      <Grid item xs={12} md={8}>
       
        <Button
          variant="contained"
          sx={{
            color: 'white',
            backgroundColor: 'rgba(240, 240, 240, 0.20)',
            borderRadius: '6px',
            fontSize: '12px',
            padding: '4px 10px',
            marginBottom: '20px',
            textTransform:'capitalize'
          }}
        >
          Join the Waitlist
        </Button>
        <Typography variant="h2"
            sx={{
              marginTop: '15px',
              fontFamily: 'Inter',
              fontStyle: "normal",
              fontWeight: 'bold',
              fontSize: isXs ? '35px' : '65px',
              color: 'white',
              overflow:'hidden',
              textAlign: 'left',
              width: 'fit-content',

             
            }} >
            The modern way to<br />transfer insurance {''}
            <span style={{
              backgroundImage: 'linear-gradient(to right, #4318FF 0%, #AAC4FF 100%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent'
            }}>risk</span>
          </Typography>
          <Typography variant="body1"
            sx={{
              marginTop: '14px',
              fontFamily: 'Inter',
              color: '#AAC4FF',
              textAlign: 'left',
              width: 'fit-content',
              fontSize: isXs ? '12px' : '20px',
            }}
          >
            Reinsurance can be challenging. We make it simple for Cedents, Brokers and Reinsurers to increase
            <br />efficiency, reduce admin and collaborate with their partners.
          </Typography>
      </Grid>

    
      <Grid item xs={12} md={4} style={{ textAlign: 'right' }}>
        <img
          src={bannerImage} 
          alt="Your Image"
          style={{ width: '50%', height: 'auto', marginLeft: isXs ? '20%' : '45%' }} 
        />
      </Grid>
    </Grid>
    <Grid >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  marginTop: '16px',
                  color: "#171717",
                  padding: "8px 12px",
                  fontFamily: "Inter",
                  fontsize: "12px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  textAlign: 'left',
                  textTransform: "none",
                }}
                disableElevation
                disableRipple
                onClick={() => handleOpen()}
              >
                Get early access
              </Button>
            </Grid>
          </Grid>
        {<CustomModal opens={open} handleClose={handleClose} />}
          <Grid item xs={12}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={15}>
          <section style={{marginTop: isXs ? '10px' : '20px',}}>
            <Typography
              sx={{
                marginTop: isXs ? '10px' : '80px',
                marginBottom: '10px',
                color: 'white',
                fontSize: '14px',
              }}
            >
              <hr style={{ border: '0.5px solid #fff rgba(255, 255, 255, 0.2)', margin: '0 0 10px 0' }} />
              Insurance Carriers
            </Typography>
            <img src={car1} alt="Insurance Carriers" style={{ width: '100%' }} />
          </section>
          <section style={{marginTop: isXs ? '10px' : '20px',}}>
            <Typography sx={{ marginTop: isXs ? '10px' : '80px', color: 'white', marginBottom: '10px', fontSize: '14px' }}>
              <hr style={{ border: '0.5px solid #fff rgba(255, 255, 255, 0.2)', margin: '0 0 10px 0' }} />
              Deal Management
            </Typography>
            <img src={car2} alt="Deal Management" style={{ width: '100%' }} />
          </section>
          <section style={{marginTop: isXs ? '10px' : '20px',}}>
            <Typography sx={{ marginTop: isXs ? '10px' : '80px', color: 'white', marginBottom: '10px', fontSize: '14px' }}>
              <hr style={{ border: '0.5px solid #fff rgba(255, 255, 255, 0.2)', margin: '0 0 10px 0' }} />
              Automated Workflow
            </Typography>
            <img src={car3} alt="Automated Workflow" style={{ width: '100%' }} />
          </section>
          <section style={{marginTop: isXs ? '10px' : '20px',}}>
            <Typography sx={{ marginTop: isXs ? '10px' : '80px', color: 'white', marginBottom: '10px', fontSize: '14px' }}>
              <hr style={{ border: '0.5px solid #fff rgba(255, 255, 255, 0.2)', margin: '0 0 10px 0' }} />
              Collaboration
            </Typography>
            <img src={car4} alt="Collaboration" style={{ width: '100%' }} />
          </section>
        </Stack>
      </Grid>
        </header>
      </Grid>
    </Grid>
  );
};

export default Banner;


