import "./Information.css";
import { Iinformation } from "Types/Types";

import tablet from "../../assets/Image Placeholder.svg";


const Information = function ({backgroundColor, icon,title,heading,text,active,onClick =() =>{}}:Iinformation<any>) {
    return (
      <>
        <img
          src={tablet}
          alt="tablet gif"
          className="mx-auto w-full mt-[24px] block tablet:hidden tablet:mt-[20px] desktop:hidden desktop:mt-[20px] mobile:hidden"
        />
        <div
          className={`information p-[20.9px] mt-[24px] ${
            active ? "active" : ""
          } transition-none duration-0 ease-none desktop:transition-all desktop:duration-500 desktop:ease-in-out tablet:transition-all tablet:duration-500 tablet:ease-in-out transform-none cursor tablet:hover:transform tablet:hover:scale-105`}
          onClick={onClick}
          style={{ backgroundColor: backgroundColor }}
        >
          <div className="flex gap-[10px]">
            <img src={icon} alt="" className="h-[24px] w-[24px]" />
            <h3 className="info-title">{title}</h3>
          </div>
          <p className="info-heading">{heading}</p>
          <p className="info-text">{text}</p>
        </div>
      </>
    );
}

export default Information;