import React, { useState } from "react";
import Footer from "Base/Footer/Footer";
import Information from "Base/Information/Information";
import Processes from "Base/Processes/Processes";
import Hero from "Base/Hero/Hero";
import Banner from "Base/Banner/Banner";
import { Grid} from "@mui/material";

import handshake from "./assets/handshake-outline.svg";
import sitemap from "./assets/sitemap-outline.svg";
import Vector from "./assets/Vector.svg";
import cogs from "./assets/cogs.svg";
import sankeys from "./assets/chart-sankey.svg";
import bubble from "./assets/chart-bubble.svg";
import large from "./assets/Image Placeholder1.svg";
import info1 from "./assets/img/info1.gif";
import info2 from "./assets/img/info2.gif";
import info3 from "./assets/img/info3.gif";

import "./App.css";

function App() {
  const [cards, setCards] = useState([
    {
      icon: handshake,
      backgroundColor: "white",
      title: "Deals",
      heading: "Collaboration between stakeholders",
      text: "Effortlessly monitor placements, enhance the precision of risk assessment, and expand your capability for deal flow, empowering you to boost profitability.",
    },
    {
      icon: sitemap,
      backgroundColor: "#5570F10A",
      title: "Workflow",
      heading: "Automated workflows for better productivity ",
      text: "Avoid redundant data entry. Create an audit trail, engage with stakeholders in real time, and establish a shared digital repository for brokers and underwriters.",
    },
  ]);

  const [showSecondGif, setShowSecondGif] = useState(false);

  const handleCardClick = (clickedCardId: number) => {
    setShowSecondGif(clickedCardId === 1);
  };

  const processes = [
    {
      icon: cogs,
      title: "",
      heading: "Manual workflows",
      text: "Cedents and Brokers spend hours preparing submission packs, working in isolated systems.",
    },
    {
      icon: sankeys,
      title: "",
      heading: "Disproportional growth capacity",
      text: "Finding needed capacity for Insurers is only limited to the network of the cedents and their brokers.",
    },
    {
      icon: bubble,
      title: "",
      heading: "No seamless connection between stakeholders",
      text: "Managing multiple communication channels with partners, constantly switching between emails, file sharing sites and policy admin systems. These multiple points limits the efficiency of stakeholders.",
    },
  ];

  return (
    <div className="w-full p-0">
      <div className="p-0 w-full">
        <Banner />
      </div>

      <main className="px-[20px] tablet:px-[40px] desktop:px-[120px] mt-[20px]" style={{ paddingLeft: "40px", paddingRight: "40px" }}>
        <Hero />
        <section id="processes" className="mb-[4.5rem] w-[100%]">
          <div className="w-[100%] flex flex-col tablet:flex-row tablet:justify-between desktop:flex-row desktop:justify-between" style={{ marginLeft: "25px", marginRight: "25px" }}>
            {processes.map((val, index) => (
              <Processes {...val} key={index} />
            ))}
          </div>
        </section>
      </main>
      <div
        style={{
          position: "relative",
          zIndex: 1,
          width: "100%",
          paddingLeft: "3rem",
          paddingRight: "3rem",
          paddingBottom: "2rem",
          paddingTop: "2rem",
          backgroundColor: "#EFEFF7",
        }}
      >
        <section id="information">
          <div className="info-wrapper">
            <h3 className="info-wrapper-header">Fortuna as a Solution</h3>
          </div>
          <div className="block tablet:grid tablet:grid-cols-4 desktop:grid desktop:grid-cols-4 gap-4">
            <div className="tablet:block desktop:block tablet:col-span-2 bigLaptop:col-span-1 overflow-hidden">
              {cards.map((val, index) => (
                <Information {...val} key={index} active={index === 0} onClick={() => handleCardClick(index)} backgroundColor={val.backgroundColor} />
              ))}
            </div>

            <div className="mt-[24px] relative tablet:col-span-2 bigLaptop:col-span-3 p-0 m-0 leading-0 gap-0">
              {showSecondGif ? (
                <img src={info2} alt="large gif" className="tablet:block m-0 mx-auto p-0 w-full mb-[24px]" style={{ border: "10px solid rgb(238 234 234)", borderRadius: "50px 30px 30px 30px", borderTop: "10px solid transparent", background: "linear-gradient(to right, #1347F5 10%, rgb(238 234 234) 0%, rgb(238 234 234) 100%)" }} />
              ) : (
                <img src={info1} alt="large gif" className="tablet:block m-0 mx-auto p-0 w-full mb-[24px]" style={{ border: "10px solid rgb(238 234 234)", borderRadius: "50px 30px 30px 30px", borderTop: "10px solid transparent", background: "linear-gradient(to right, #1347F5 10%, rgb(238 234 234) 0%, rgb(238 234 234) 100%)" }} />
              )}
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default App;
