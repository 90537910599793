import "./Hero.css";

const Hero = function () {
  const isXs = window.innerWidth < 600;
    return (
      <section id="hero" style={{paddingLeft: isXs ? '3rem' : '5rem', paddingRight: isXs ? '3rem' : '5rem',}}>
        <div className="w-[100%] mx-auto flex justify-center gap-[16px]">
          <p className="hero-question-one" style={{backgroundColor:'#1347F5',color:'white', }}>Software for Reinsurance</p>
        </div>
        <p className="mx-auto hero-header text-center" >
          Backed by research and built for optimal <br/>workflow management
        </p>
        <p className="mx-auto hero-caption text-center" style={{marginBottom:'2rem'}}>
          Trusted by brokers and insurance companies
        </p>
      </section>
    );
};

export default Hero;

