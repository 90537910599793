import "./Processes.css";
import { Iinformation } from "Types/Types";

const Processes = function ({ icon, heading, text }: Iinformation<any>) {
  return (
    <div className="process-information px-0 tablet:px-[20.9px] desktop:px-[20.9px]  mt-[24px]">
      <div className="process-icon-wrapper">
        <img src={icon} alt="" className="h-[24px] w-[24px]" />
      </div>
      <div>
        <p className="info-heading">{heading}</p>
        <p className="info-text">{text}</p>
      </div>
    </div>
  );
};

export default Processes;
