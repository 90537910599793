import { createTheme } from "@mui/material";


export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1347F5",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#1347F5",
      main: "#fff",
    },
  },
  zIndex: {
    // appBar: 2000,
  },
  //   typography: {
  //     fontFamily: "Montserrat",
  //     fontSize: 11,
  //   },
  //   components: {
  //     MuiCssBaseline: {
  //       styleOverrides: `
  //           @font-face {
  //             font-family: 'Montserrat';
  //             font-style: normal;
  //             font-display: swap;
  //             font-weight: 200;
  //             src: local('Montserrat'), local('Montserrat-VariableFont_wght'), url(${Montserrat}) format('ttf');
  //             unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  //           }
  //         `,
  //     },
  //     MuiButton: {
  //       styleOverrides: {
  //         root: ({ ownerState }) => ({
  //           ...(ownerState.variant === "contained" &&
  //             ownerState.color === "primary" && {
  //               backgroundColor: wine,
  //               color: white,
  //             }),
  //         }),
  //       },
  //     },
  //   },
});