
import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Dialog, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Swal from 'sweetalert2';

import "./Modal.css";

interface Imodal {
  opens: boolean;
  handleClose: Function;
}

export const CustomModal: React.FC<Imodal> = ({ opens, handleClose }) => {

const [open] = useState(opens);

const isXs = window.innerWidth < 600;
const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (loading) {
      return;
    }
    const formData = new FormData(e.currentTarget);

    try {
      const response = await fetch('https://sheetdb.io/api/v1/lzx18f8lf8bn5', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data.created === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Form submitted successfully!',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error submitting the form. Please try again.',
          
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred. Please check your internet connection and try again.',
      });
    } finally {
      setLoading(false); 
      handleClose(); 
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "604px",
    bgcolor: "#fff",
    border: "0px solid #000",
    boxShadow: 24,
    p: "28px 24px",
    borderRadius: "8px",
    marginTop: isXs ? '50px' : '1px',
  };

  
  useEffect(() => {}, [open]);

  return (
    <Dialog
      fullWidth
      open={Boolean(opens)}
      onClose={() => handleClose()}
      maxWidth="lg"
      sx={{
        backdropFilter: "blur(4px)",
        padding: "0px",
      }}
      hideBackdrop
    >
      <Modal
        open={Boolean(opens)}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-full flex justify-between items-center">
            <p className="modal-title">Get Early Access</p>

            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <p className="modal-caption" style={{fontSize:'16px', fontWeight:'bold'}}>
            Please get in touch on the form below and we will be in touch shortly.
          </p>
          <form id="modalForm" onSubmit={handleSubmit}>
            <div className="w-full mt-[27px] flex flex-col gap-[24px] tablet:flex-row tablet:gap-[8.5px]">
              <input
                type="text"
                placeholder="First Name"
                name="data[First Name]"
                className="modal-input"
                required
              />
              <input
                type="text"
                placeholder="Last Name"
                name="data[Last Name]"
                className="modal-input"
                required
              />
            </div>
            <div className="w-full mt-[24px]">
              <input
                type="email"
                placeholder="Email Address"
                name="data[Email]"
                className="modal-input"
                required
              />
            </div>
            <div className="w-full mt-[24px]">
            <select
              className="py-2 pl-[14px] pr-10 bg-[#f4f7fe] bg-no-repeat bg-right-2 border rounded-[10px] h-[46px] w-full"
              name="data[Company Type]"
              required
            >
              <option value="">Select Company Type</option>
              <option value="Reinsurance">Reinsurance</option>
              <option value="Insurance Company">Insurance Company</option>
              <option value="Broker">Broker</option>
              <option value="Client">Client</option>
            </select>

            </div>
            <div className="w-full mt-[24px]">
              <input
                type="text"
                placeholder="Company"
                name="data[Company Name]"
                className="modal-input"
                required
              />
            </div>
            <div className="mt-[44px] flex flex-col items-center justify-center gap-4 tablet:justify-between tablet:flex-row-reverse tablet:items-center">
              <Button 
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  color: "#FFF",
                  /* Button text/M */
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "28px" /* 175% */,
                  letterSpacing: "-0.176px",
                  textTransform: "none",
                  cursor: loading ? 'not-allowed' : 'pointer',
                  visibility: loading ? 'visible' : 'visible',
                }}
                disableElevation
                disableRipple
                disableFocusRipple
                disableTouchRipple
                
              >
                {loading ? <CircularProgress size={20} color="inherit" /> : 'Submit'}
              </Button>
              {/* <p className="modal-request">Request Live Demo</p> */}
              {/* <a className="modal-request" href="https://calendly.com/fortuna-re/30min?month=2023-11"
                    target="_blank">
                  Request Live Demo
              </a> */}
            </div>
          </form>
        </Box>
      </Modal>
    </Dialog>
  );
};