import * as React from "react";
import { Grid, Stack, Button, Typography, Link } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function Footer() {

  const isXs = window.innerWidth < 600;
  
  return (
    // <Grid container spacing={3} sx={{ padding: 2 }}>
    //   <Grid item xs={12} md={4}>
    //     <Stack
    //       alignItems="flex-start"
    //       padding={3}
    //       borderRadius={2}
    //       color="white"
    //       bgcolor="#efeff7"
    //       sx={{
    //         backgroundColor: '#161313',
    //         width: '100%',
    //         minHeight: 240,
    //       }}
    //     >
    //       <Typography variant="h6">Write better risks, faster</Typography>
    //       <Typography variant="subtitle1">Join the future of insurance</Typography>
    //       <Stack direction="row" spacing={1} sx={{ marginTop: '80px' }}>
    //         <Button
    //           variant="contained"
    //           color="primary"
    //           endIcon={<ArrowForwardIcon sx={{ marginLeft: '4px' }} />}
    //           sx={{ textTransform: 'none' }}
    //         >
    //           Join Waitlist
    //         </Button>
    //       </Stack>
    //     </Stack>
    //   </Grid>
    //   <Grid item xs={12} md={8}>
    //     <Stack direction="row" bgcolor="#efeff7" padding={2} borderRadius={2} spacing={2} sx={{ width: '100%', height:'100%', fontSize: '10px', overflow:'hidden' }}>
    //       <Stack direction="column" spacing={2} sx={{ flex: 1 }}>
    //         <Typography>Partners</Typography>
    //         <Typography>Demo</Typography>
    //       </Stack>
    //       <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
    //         <Typography>Community</Typography>
    //         <Typography>Get Support</Typography>
    //         <Typography>Help Center</Typography>
    //       </Stack>
    //       <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
    //         <Typography>Learn</Typography>
    //         <Typography>Blog</Typography>
    //         <Typography>Features</Typography>
    //       </Stack>
    //       <img
    //         loading="lazy"
    //         src="https://cdn.builder.io/api/v1/image/assets/TEMP/2ca91ebc035d015752d304eb64b18c5b111b67c9fdc07aa9763fd1a6310eec90?apiKey=8a4a497c78484cdca20be29f4a6b7b43&"
    //         alt="Information graphic"
    //         style={{ flex: '0 0 auto', maxHeight: '100%', objectFit: 'cover', height:'10%' }}
    //       />
    //       <Stack>
    //       <nav>
    //               <Link href="#" variant="body1" color="inherit" underline="hover">
    //                 Privacy Policy
    //               </Link>
    //               <Link href="#" variant="body1" color="inherit" underline="hover">
    //                 Terms of Use
    //               </Link>
    //             </nav>
    //             <Typography variant="body1">© 2023 FORTUNA.</Typography>
    //       </Stack>
    //     </Stack>
       
    //   </Grid>
    // </Grid>
    <Grid container spacing={3} sx={{ padding: 2 }}>
    <Grid item xs={12} md={4}>
      <Stack alignItems="flex-start" padding={3} borderRadius={2} color="white" bgcolor="#efeff7" sx={{ backgroundColor: '#161313', width: '100%', minHeight: 240 }}>
        <Typography variant="h6">Write better risks, faster</Typography>
        <Typography variant="subtitle1">Join the future of insurance</Typography>
        <Stack direction="row" spacing={1} sx={{ marginTop: '80px' }}>
          <Button variant="contained" color="primary" endIcon={<ArrowForwardIcon sx={{ marginLeft: '4px' }} />} sx={{ textTransform: 'none' }}>
            Join Waitlist
          </Button>
        </Stack>
      </Stack>
    </Grid>
    <Grid item xs={12} md={8}>
      <Stack direction="column" sx={{ width: '100%', backgroundColor: '#efeff7', borderRadius: 2, height: '240px', fontSize: '10px', overflow: 'hidden' }}>
        <Stack direction="row" spacing={2} sx={{ flex: 1,  padding: 2, marginTop:'20px'  }}>
          <Stack direction="column" spacing={2}  sx={{ flex: 1 }}>
            <Typography sx={{color:'#a69fa0'}}>Partners</Typography>
            <Typography>Demo</Typography>
          </Stack>
          <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{color:'#a69fa0'}}>Community</Typography>
            <Typography>Get Support</Typography>
            <Typography>Help Center</Typography>
          </Stack>
          <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
            <Typography sx={{color:'#a69fa0'}}>Learn</Typography>
            <Typography>Blog</Typography>
            <Typography>Features</Typography>
          </Stack>
          {!isXs && (
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2ca91ebc035d015752d304eb64b18c5b111b67c9fdc07aa9763fd1a6310eec90?apiKey=8a4a497c78484cdca20be29f4a6b7b43&"
            alt="Information graphic"
            style={{
              flex: '0 0 auto',
              maxHeight: '100%',
              objectFit: 'cover',
              height: '15%',
            }}
          />
        )}
      </Stack>
        
      <Stack direction="row" sx={{ padding: 2, borderRadius: 2, alignItems: 'center' }}>
        <nav style={{ display: 'flex', alignItems: 'center' }}>
          <Link href="#" variant="body1" color="inherit" underline="hover">
            Privacy Policy
          </Link>
          {!isXs && ( 
          <Typography variant="body1" sx={{ marginLeft: '10px', marginRight: '10px', fontSize:'2rem', color:'#d0cccc' }}>•</Typography>
          )}
          <Link href="#" variant="body1" color="inherit" underline="hover" sx={{ ...(isXs && { marginLeft: '50px' }) }}>
            Terms of Use
          </Link>
        </nav>
        <Typography variant="body1" sx={{ marginLeft: isXs ? '60px' : 'auto' }}>© 2023 FORTUNA.</Typography>
      </Stack>
    </Stack>
    </Grid>
  </Grid>
  
  

  );
}

export default Footer;